type Declension = [string, string, string]
type Plural = [string, string]

export const clinicAccusativeDeclension = ['клинику', 'клиники', 'клиник']
export const clinicDeclension: Declension = ['клиника', 'клиники', 'клиник']
export const clinicMultipleDeclension: Declension = ['клиникой', 'клиниками', 'клиниками']
export const connectedClinicDeclension: Declension = ['подключена', 'подключены', 'подключено']
export const partnerDeclension: Declension = ['партнёр', 'партнёра', 'партнёров']
export const appointmentDeclension: Declension = ['запись', 'записи', 'записей']
export const integrationDeclension: Declension = ['интеграция', 'интеграции', 'интеграций']
export const readyDeclension: Declension = ['готовая', 'готовые', 'готовых']

export const readyPlural: Plural = ['готова', 'готовы']
export const serviceCreativeCasePlural: Plural = ['сервисом', 'сервисами']
