<template>
  <section
    :class="{
      'landing-container': $featureFlag('feature_show_new_landing')
    }"
  >
    <template v-if="$featureFlag('feature_show_new_landing')">
      <div class="mb-6">
        <h2 class="text-center text-h5 text-md-h4">
          <slot name="title">{{ title }}</slot>
        </h2>
        <p class="text-md-landing-h6 text-center mt-4 text-ui-kit-text-secondary">
          <slot name="subtitle">{{ subtitle }}</slot>
        </p>
      </div>
      <slot />
    </template>

    <v-container v-else>
      <div class="mb-6 mb-md-10">
        <h2 class="text-center text-h4">
          <slot name="title">{{ title }}</slot>
        </h2>
        <p class="text-body-2 mt-md-4 text-md-body-1 text-center">
          <slot name="subtitle">{{ subtitle }}</slot>
        </p>
      </div>
      <slot />
    </v-container>
  </section>
</template>

<script lang="ts">
export default defineComponent({
  name: 'HomeSection',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  }
})
</script>
